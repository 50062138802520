@font-face {
  font-family: "Bluesmile";
  src: url("./assets/fonts/BluesSmile.otf");
  src: local("Bluesmile"),
    url("./assets/fonts/BluesSmile.ttf") format("truetype");
  font-weight: normal;
}

body {
  background-color: #f90816;
  color: #fff;
  min-height: 100vh;
  font-family: Bluesmile, sans-serif;
  font-size: 16px;
}

.tokenomics {
  margin-top: 50px;
  display: flex;
  gap: 100px;
  li {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    span {
      &:first-child {
        font-size: 25px;
      }
      &:last-child {
        font-size: 18px;
      }
    }
  }
}

.app {
  min-height: 100vh;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  @media screen and (max-width: 600px) {
    padding: 50px 20px;
  }
}

.menu {
  display: flex;
  justify-content: center;
  gap: 50px;
  font-size: 20px;
  @media screen and (max-width: 600px) {
    font-size: 18px;
    gap: 30px;
  }
}

.logo {
  margin-top: 80px;
  max-width: 300px;
  max-height: 300px;
  @media screen and (max-width: 600px) {
    margin-top: 50px;
  }
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.title {
  font-size: 35px;
  margin-top: 30px;
}

.mt-4 {
  margin-top: 30px;
}

.description {
  max-width: 700px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  line-height: 1.4;
  font-size: 16px;
  @media screen and (max-width: 600px) {
    line-height: 1.2;
  }
}

.btn-buy {
  margin-top: 30px;
  color: #ff0716;
  background-color: #fff;
  padding: 13px 30px;
  border-radius: 18px;
  border: 2px solid #c4c4c4;
}

.social {
  margin-top: 50px;
  display: flex;
  li {
    margin: 0 10px;
    @media screen and (max-width: 350px) {
      margin: 0 8px;
    }
    img {
      @media screen and (max-width: 400px) {
        width: 35px;
      }
    }
  }
}

.copyright {
  display: block;
  margin-top: 100px;
  @media screen and (max-width: 600px) {
    margin-top: 50px;
  }
}
